import React from "react";
import { string, bool, shape, arrayOf } from "prop-types";
import { motion } from "framer-motion";
import { isMobileOnly } from "react-device-detect";
import Heading from "../../../Base/HeadingBuilder";
import CtaPrimary from "../../../Base/CtaPrimary";
import AppetizeImage from "../../../../components/ImageQuerys/AppetizeImages";
import useStartMotion from "../../../../hooks/use-start-motion";
import SectionHighlights from "../../SectionHighlights";
import background from "../../../../images/svg/vector-06.svg";

const slideBottomVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: {
    opacity: 1,
    y: 0,
  },
};

const HighlightsHeroExtended = ({
  className,
  image,
  title,
  titleSymbol,
  subtext,
  highlights,
  ctaPrimary,
  animations,
}) => {
  const { ref, controls } = useStartMotion();

  return (
    <section
      className={`hh-extended
        relative
        max-w-1440 w-full
        ${className}
      `}
      ref={ref}
    >
      {/* Image */}
      <motion.div
        className="relative px-4 lg:px-10 -mb-44 lg:-mb-40 z-1"
        animate={controls}
        initial={isMobileOnly || !animations ? "visible" : "hidden"}
        variants={slideBottomVariants}
        transition={{ delay: 0.1, duration: 0.8 }}
      >
        <AppetizeImage
          className="hh-top-image rounded-large"
          imageName={image.imageName}
        />
        <div
          className={`absolute
          max-w-70-percent left-8 bottom-11
          md:left-16
          md:max-w-lg
          xl:left-40 `}
        >
          <Heading
            className="text-white"
            level={2}
            injectionType={3}
            withBlueSymbol
          >
            {image.title}
          </Heading>
          <p className="text-black-600 text-p">{image.subTitle}</p>
        </div>
        <div
          className={`
          absolute text-white transform -rotate-90
          top-28 -right-8
          md:right-0
          lg:top-auto lg:bottom-28
          xl:right-20
          `}
        >
          {image.subtext}
        </div>
      </motion.div>

      {/* Content */}
      <div
        className={`
        relative bg-black w-full rounded-large
        pt-72 lg:pt-80
        px-4 md:px-16 xl:px-40 
        pb-20 lg:pb-40
        `}
      >
        <img
          className="absolute -top-32 right-0 transform lg:scale-200"
          src={background}
          alt="background"
        />
        <div className="grid grid-cols-1 lg:grid-cols-2 items-end lg:gap-x-56">
          <Heading
            className="text-white"
            withBlueSymbol
            symbol={titleSymbol}
            noMargin
            level={2}
            injectionType={1}
          >
            {title}
          </Heading>

          <p className="text-black-600 justify-self-end text-p">{subtext}</p>

          <CtaPrimary
            className="my-10 md:mb-0 lg:mb-8"
            title={ctaPrimary.title}
            target={ctaPrimary.target}
          />
        </div>

        <SectionHighlights
          highlights={highlights}
          animations={animations}
          withWhiteText
          extended
        />
      </div>

      <style jsx>{`
        :global(.hh-top-image) {
          min-height: 600px;
        }
        :global(.hh-extended h1) {
          margin-bottom: 0 !important;
        }
      `}</style>
    </section>
  );
};

HighlightsHeroExtended.propTypes = {
  className: string,
  image: shape({
    imageName: string,
    title: string,
    subTitle: string,
    subtext: string,
  }).isRequired,
  title: string.isRequired,
  titleSymbol: string,
  subtext: string,
  highlights: arrayOf(
    shape({
      title: string,
      subtext: string,
    })
  ).isRequired,
  ctaPrimary: shape({
    title: string,
    target: string,
  }),
  animations: bool,
};

HighlightsHeroExtended.defaultProps = {
  className: "",
  titleSymbol: ".",
  subtext: "",
  ctaPrimary: null,
  animations: true,
};

export default HighlightsHeroExtended;
